/*#region General*/
@use "/src/styles/abstracts/variables" as vars;

html {
  font-size: 55.5% !important;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
  font-size: var(--font-md);
}

/*#endregion General*/

/*#region Custom Angular Material*/ 

.swal2-popup {
  white-space: pre-wrap;
  word-wrap: break-word;
}

::ng-deep .mat-form-field:not(.mat-form-field-has-label) .mat-form-field-infix {
  border-top-width: 0;
}

.mat-drawer-inner-container {
  display: flex !important;
  flex-direction: column !important;
}

.matsnack-bar-action {
  color: vars.$color-button-snackbar;
}

::ng-deep .mat-select-arrow-wrapper {
  transform: rotate(0deg) !important;
}

::ng-deep .mat-form-field-wrapper {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

::ng-deep .mat-form-field-suffix {
  top: 0.5rem !important;
}

::ng-deep .mat-button-focus-overlay {
  background-color: transparent !important;
}

mat-header-cell, mat-footer-cell {
  font-size: var(--font-xxm) !important;
  font-weight: bold !important; /* Keep bold for header cells */
  color: #5f5f5f !important;
  background-color: white !important;
  padding: 10px 10px !important; /* Adjust padding */
  display: flex; /* Keep flex for alignment */
  align-items: center; /* Align items vertically */
  overflow: hidden;
  word-wrap: break-word;
  min-height: 40px; /* Set a min-height for consistency */
}

mat-cell {
  font-size: var(--font-xxm) !important; /* Same font size for uniformity */
  font-weight: normal !important; /* Normal weight for regular cells */
  color: #5f5f5f !important;
  padding: 10px 10px !important; /* Match padding with header */
  display: flex; /* Use flex for alignment */
  align-items: center; /* Center items vertically */
  overflow: hidden;
  word-wrap: break-word;
  min-height: 40px; /* Set same min-height for consistency */
}

::ng-deep .mat-header-row {
  top: -16px !important;
  z-index: 2 !important;
}


.mat-icon { font-size: 24px !important; height: 24px !important; width: 24px !important; }

::ng-deep .mat-sort-header-content {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 800;
}

.mat-mdc-menu-item {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.mat-mdc-menu-item .mat-icon {
  display: inline-flex !important;
  align-items: center !important;
}

/* Global padding for dialog content */
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;  /* Adjust the padding as needed */
}

.mat-mdc-form-field-icon-prefix {
  line-height: 1.6 !important;
}

.mdc-tab-indicator--active {
  background-color: #0876a5 !important;
  color:white !important;
}
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: white !important;
}