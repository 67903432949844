$light-grey: #a3a3a3;
$grey: #f6f6f6;
$regular-grey: #edeff2;
$cerebral-grey: #cccccc;
$cool-grey: #aaaaaa;
$dark-gray: #999999;
$iron-gray: #666666;
$very-dark-gray: #5f5f5f;
$shadow-gray: #424242;
$lava-gray: rgba(153, 153, 153, 0.2);

$white-color: #ffffff;
$black-color: #333333;
$primary-blue: #5388ee;
$sky-blue: #77a4f9;
$lapis-blue: #1a62ce;
$sapphire-blue: rgba(119, 164, 249, 0.2);
$green-jungle: #109098;
$green-success: #155724;
$green-lime: #51b655;
$green-kelly: #55b859;
$green-mint: rgba(85, 184, 89, 0.2);
$red-danger: #721c24;
$red-normal: #fa5436;
$red-rose: rgb(211, 81, 81);
$blue-info: #0c5460;
$orange-warning: #856404;
$red-reset: #fa5436;
$tuscany-yellow: #da9f1a;
$butter-yellow: rgba(218, 159, 26, 0.2);

$box-shadow: 0 4px 7px 0 rgba(51, 51, 51, 0.25);
$box-shadow-layout: 0 2px 4px 2px rgba(51, 51, 51, 0.25);
$box-shadow-button: 0 2px 4px 0 rgba(51, 51, 51, 0.25);
$border-shadow-risk-assessment: inset 0 -2px 0 0 #edeff2;
$border-shadow-box-risk-assessment: 0 2px 4px 0 rgba(51, 51, 51, 0.25);

$color-default-app: $red-rose;
$color-default-button-background: $primary-blue;
$color-default-navbar-background: $red-rose;
$color-default-button-sidenav-background: $red-rose;
$color-button-snackbar: $primary-blue;
$color-field-form: $grey;

$color-loader: $white-color;
$color-card-module: $white-color;
$color-hover-card: $grey;
$color-card-form-dialog: $regular-grey;
$color-notif-success: $green-lime;
$color-notif-failed: $red-normal;
$color-card-notif: $white-color;

$color-card-filter-not-started: $cool-grey;
$color-card-filter-in-progress: $sky-blue;
$color-card-filter-submitted: $tuscany-yellow;
$color-card-filter-completed: $green-kelly;
$color-card-filter-text-selected: $black-color;
$color-card-filter-text: $iron-gray;
$color-card-background-unselected: $white-color;

$color-list-item-background: $white-color;
$color-icon: $white-color;

$color-confirm-dialog-button: $primary-blue;
$color-risk-indication: $white-color;
$color-risk-assessment-background: $white-color;

// Menu colors
$color-icon-menu: $very-dark-gray;
$color-text-menu: $very-dark-gray;

$color-icon-active-menu: $red-rose;
$color-text-active-menu: $red-rose;
$color-left-border-active-menu: $red-rose;
$color-background-active-menu: $regular-grey;

$color-icon-expand-menu: $red-rose;

// Table colors
$color-table-header: $very-dark-gray;
$color-table-divider:  rgba(95, 95, 95, 0.1);

// primary font
$primary_font: 'Montserrat-Medium';
$title_font: 'Montserrat-SemiBold';
$button_font: 'Source Sans Pro';

// font-weight
$thin: 100;
$light: 300;
$regular: 400;
$light-bold: 600;
$bold: 700;
$font-weight-black: 900;

// Font size
$font-xxs: 1rem;
$font-xs: 1.2rem;
$font-sm: 1.4rem;
$font-xm: 1.6rem;
$font-md: 1.8rem;
$font-lg: 2rem;
$font-xl: 2.4rem;
$font-xxl: 3.6rem;
$font-xxxl: 4.6rem;

$height: 7rem;

:root {
  --font-body: 1.4rem;
  --font-xxs: 1rem;
  --font-xs: 1.2rem;
  --font-sm: 1.4rem;
  --font-xxm:1.5rem;
  --font-xm: 1.6rem;
  --font-md: 1.8rem;
  --font-lg: 2rem;
  --font-xl: 2.4rem;
  --font-xxl: 3.6rem;
  --font-xxxl: 4.6rem;

  --width-risk-indication: 5.4rem;
  --width-risk-indication-wider: 7.8rem;
}

// breakpoints
$desktop: 960px;
$tablet: 768px;
$mobile-large: 640px;
$mobile: 480px;
$mobile-small: 325px;

$breakpoints: ('mobile': (max-width: $mobile),
  'tablet': (max-width: $tablet),
  'desktop': (min-width: $desktop),
  'mobile-large': (max-width: $mobile-large),
  'mobile-small': (max-width: $mobile-small));
