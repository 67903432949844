@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'node_modules/sweetalert2/src/sweetalert2';
@use 'base/reset';
@use 'features/mat-table-responsive';
@use 'themes/theme';
@use 'themes/font';
@use 'themes/icons';

html.swal2-shown, body.swal2-shown {
  overflow-y: auto !important;
  height: auto !important;
}

// Parce que Bootstrap met un font de 10px au HTML ce qui faisait que SweetAlert2 était tout petit
// Référence : https://github.com/sweetalert2/sweetalert2/issues/834
.swal2-popup {
  font-size: 1.6rem !important;
}
