@font-face {
	font-family: Montserrat-Medium;
	src: url(../../assets/fonts/Montserrat-Medium.ttf) format("woff");
	font-style: normal;
}

@font-face {
	font-family: Montserrat-SemiBold;
	src: url(../../assets/fonts/Montserrat-SemiBold.ttf) format("woff");
	font-style: normal;
}
